body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background: url("../public/Background.png") no-repeat;
}

main {
  flex: 1 0 auto;
}

h1.title,
.footer-copyright a {
  font-family: 'Architects Daughter', cursive;
  text-transform: uppercase;
  font-weight: 900;
}

/* Start welcome animation */

body.welcome {
  background: #512da8;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
}

.welcome .splash {
  height: 0;
  padding: 0;
  border: 130em solid #6e2a7c;
  position: fixed;
  left: 50%;
  top: 100%;
  display: block;
  box-sizing: initial;
  overflow: hidden;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: puff 0.5s 1.8s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards,
             borderRadius 0.2s 2.3s linear forwards;
}

.welcome #welcome {
  background: #9225c5;
  width: 56px;
  height: 56px;
  position: absolute;
  left: 50%;
  top: 50%;
  overflow: hidden;
  opacity: 0;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  animation: init 0.5s 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards,
             moveDown 1s 0.8s cubic-bezier(0.6, -0.28, 0.735, 0.045) forwards,
             moveUp 1s 1.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards,
             materia 0.5s 2.7s cubic-bezier(0.86, 0, 0.07, 1) forwards,
             hide 2s 2.9s ease forwards;
}

/* Move in */
.welcome header,
.welcome a.btn {
  opacity: 0;
  animation: moveIn 2s 3.1s ease forwards;
}
.social-icons {
  position: fixed; /* Fix the position relative to the viewport */
  bottom: 20px; /* Adjust distance from the bottom */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Offset to center it */
  display: flex;
  justify-content: center;
}

.social-icons a {
  margin: 0 15px; /* Add horizontal spacing between icons */
  /* Default color of icons */
  transition: color 0.3s ease; /* Smooth transition for hover effect */
}

.social-icons a:hover {
  color: green; /* Hover color */
}

.social-icons i:hover {
  transition: color 0.3s ease, transform 0.3s ease;
  color: rgb(187, 41, 201); 
  font-size: 2em; /* Ensure icons have a good size */
  transform: scale(1.2);
}


@keyframes init {
  0% {
    width: 0;
    height: 0;
  }
  100% {
    width: 56px;
    height: 56px;
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes puff {
  0% {
    top: 100%;
    height: 0;
    padding: 0;
  }
  100% {
    top: 50%;
    height: 100%;
    padding: 0 100%;
  }
}

@keyframes borderRadius {
  0% {
    border-radius: 50%;
  }
  100% {
    border-radius: 0;
  }
}

@keyframes moveDown {
  0% {
    top: 50%;
  }
  50% {
    top: 40%;
  }
  100% {
    top: 100%;
  }
}

@keyframes moveUp {
  0% {
    background: #651fb4;
    top: 100%;
  }
  50% {
    top: 40%;
  }
  100% {
    top: 50%;
    background: #df64e4;
  }
}
.logo {
  height: 10px; /* Adjust height as needed */
}
@keyframes materia {
  0% {
    background: #822cc9;
  }
  50% {
    background: #8122c0;
    top: 26px;
  }
  100% {
    background: #cc2dec;
    width: 100%;
    height: 64px;
    border-radius: 0;
    top: 26px;
  }
}

@keyframes moveIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
